import React from 'react';
import { Box, Typography, IconButton, LinearProgress } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const EndpointBox = styled(Box)`
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const ProjectHeader = ({ project }) => {
  const maxResources = 10;
  const currentResources = project.resources ? project.resources.length : 0;
  const progress = (currentResources / maxResources) * 100;

  return (
    <>
      <Header>
        <Typography variant="h4">{project.name}</Typography>
        <Link to="/projects">
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
        </Link>
      </Header>
      <ProgressContainer>
        <Typography variant="body1" sx={{ paddingBottom: 1 }}>
          Resources: {currentResources} / {maxResources}
        </Typography>
        <LinearProgress variant="determinate" value={progress} sx={{ width: '100%' }} />
      </ProgressContainer>
      <EndpointBox>
        <Typography variant="h6" sx={{ paddingBottom: 2 }}>API endpoint</Typography>
        <Typography variant="body1" sx={{ paddingBottom: 2 }}>
          <a href={`http://${project.id}.${process.env.REACT_APP_DOMAIN}${project.prefix}:endpoint`} target="_blank" rel="noopener noreferrer">
            http://{project.id}.{process.env.REACT_APP_DOMAIN}{project.prefix}:endpoint
          </a>
        </Typography>
      </EndpointBox>
    </>
  );
};

export default ProjectHeader;
