import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import styled from 'styled-components';
import ProjectHeader from './ProjectHeader';
import ResourceList from '../resource/ResourceList';
import ProjectDialogsDitails from './ProjectDialogsDitails';
import NewResourceButton from '../NewResourceButton';
import _ from 'lodash';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  max-width: 800px;
`;

const ProjectDetails = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [resources, setResources] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [dataDialogOpen, setDataDialogOpen] = useState(false);
  const [resourceToDelete, setResourceToDelete] = useState(null);
  const [resourceToEdit, setResourceToEdit] = useState(null);
  const [resourceData, setResourceData] = useState([]);
  const debounceRef = useRef(null);

  const fetchProject = useCallback(async () => {
    try {
      const response = await axios.get(`/api/v1/projects/${projectId}`);
      setProject(response.data);
      setResources(response.data.resources);
    } catch (error) {
      console.error('Error fetching project details', error);
    }
  }, [projectId]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject]);

  const handleResourceCreated = () => {
    fetchProject();
  };

  const handleDeleteClick = (resource) => {
    setResourceToDelete(resource);
    setDeleteDialogOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteDialogOpen(false);
    setResourceToDelete(null);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`/api/v1/resources/${resourceToDelete.id}`);
      setResources(resources.filter((res) => res.id !== resourceToDelete.id));
      handleDeleteClose();
    } catch (error) {
      console.error('Error deleting resource', error);
    }
  };

  const debounceUpdateResourceCount = useCallback((resource, value) => {
    if (!debounceRef.current) {
      debounceRef.current = _.debounce(async (res, val) => {
        try {
          await axios.post(`/api/v1/generate/${res.id}`, { count: val });
          fetchProject(); // Обновляем данные после изменения
        } catch (error) {
          console.error('Error updating resource count', error);
        }
      }, 250);
    }
    debounceRef.current(resource, value);
  }, [fetchProject]);

  const handleSliderChange = (resource, value) => {
    debounceUpdateResourceCount(resource, value);
  };

  const handleDataClick = async (resource) => {
    try {
      const response = await axios.get(`/api/v1/data/${resource.id}`);
      setResourceToEdit(resource);
      setResourceData(JSON.parse(response.data.mock_data));
      setDataDialogOpen(true);
    } catch (error) {
      console.error('Error fetching resource data', error);
    }
  };

  const handleDataClose = () => {
    setDataDialogOpen(false);
    setResourceToEdit(null);
    setResourceData([]);
  };

  const handleDataUpdate = async () => {
    try {
      await axios.put(`/api/v1/data/${resourceToEdit.id}`, { mock_data: JSON.stringify(resourceData) });
      fetchProject(); // Обновляем данные после изменения
      handleDataClose();
    } catch (error) {
      console.error('Error updating resource data', error);
    }
  };

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <ProjectHeader project={project} />
      <NewResourceButton projectId={project.id} onResourceCreated={handleResourceCreated} />
      <ResourceList
        resources={resources}
        project={project}
        handleSliderChange={handleSliderChange}
        handleDataClick={handleDataClick}
        handleDeleteClick={handleDeleteClick}
        handleResourceUpdated={handleResourceCreated} // Передаем функцию обновления
      />
      <ProjectDialogsDitails
        deleteDialogOpen={deleteDialogOpen}
        dataDialogOpen={dataDialogOpen}
        handleDeleteClose={handleDeleteClose}
        handleDeleteConfirm={handleDeleteConfirm}
        handleDataClose={handleDataClose}
        handleDataUpdate={handleDataUpdate}
        resourceToDelete={resourceToDelete}
        resourceToEdit={resourceToEdit}
        resourceData={resourceData}
        setResourceData={setResourceData}
      />
    </Container>
  );
};

export default ProjectDetails;
