import React from 'react';
import Main from '../components/Main';
// import Pricing from '../components/Pricing';
import styled from 'styled-components';

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Home = () => (
  <HomeContainer>
    <Main />
    {/* <Pricing /> */}
  </HomeContainer>
);

export default Home;
