import React from 'react';
import { Menu, MenuItem, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, TextField, Button, Typography, Divider, ListItemIcon, IconButton, Alert } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

const ProjectDialogs = ({
  anchorEl, menuSelectedProject, deleteDialogOpen, createDialogOpen, editDialogOpen,
  newProjectName, newProjectPrefix, setNewProjectName, setNewProjectPrefix,
  handleMenuClose, handleDeleteClick, handleDeleteClose, handleDeleteConfirm,
  handleCreateClose, handleCreateConfirm, handleEditClick, handleEditClose, handleEditConfirm, error, setError
}) => {

  const handleEditConfirmWrapper = async () => {
    try {
      await handleEditConfirm();
      setError('');
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred');
    }
  };

  const handleCreateCloseWrapper = () => {
    handleCreateClose();
    setError('');
  };

  const handleEditCloseWrapper = () => {
    handleEditClose();
    setError('');
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEditClick}>
          <ListItemIcon>
            <EditIcon fontSize='small' />
          </ListItemIcon>
          <Typography variant="inherit">Edit</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <DeleteIcon color='error' fontSize='small' />
          </ListItemIcon>
          <Typography variant="inherit">Delete</Typography>
        </MenuItem>
      </Menu>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteClose}
      >
        <DialogTitle>Delete Project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete <strong>{menuSelectedProject ? menuSelectedProject.name : ''}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>Close</Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained">Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={createDialogOpen}
        onClose={handleCreateCloseWrapper}
        fullWidth
      >
        <DialogTitle>
          New Project
          <IconButton aria-label="close" onClick={handleCreateCloseWrapper} sx={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              {error}
            </Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            required
            variant="outlined"
            value={newProjectName}
            onChange={(e) => setNewProjectName(e.target.value)}
            placeholder="Example: Todo App, Project X..."
          />
          <TextField
            margin="dense"
            label="API Prefix"
            type="text"
            fullWidth
            required
            variant="outlined"
            value={newProjectPrefix}
            onChange={(e) => setNewProjectPrefix(e.target.value)}
            placeholder="Example: /api/v1"
            helperText="Add API prefix to all endpoints in this project."
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateCloseWrapper}>Cancel</Button>
          <Button onClick={handleCreateConfirm} color="primary" variant="contained">Create</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={editDialogOpen}
        onClose={handleEditCloseWrapper}
        fullWidth
      >
        <DialogTitle>
          Edit Project
          <IconButton aria-label="close" onClick={handleEditCloseWrapper} sx={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              {error}
            </Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newProjectName}
            onChange={(e) => setNewProjectName(e.target.value)}
            placeholder="Example: Todo App, Project X..."
          />
          <TextField
            margin="dense"
            label="API Prefix"
            type="text"
            fullWidth
            variant="outlined"
            value={newProjectPrefix}
            onChange={(e) => setNewProjectPrefix(e.target.value)}
            placeholder="Example: /api/v1"
            helperText="Add API prefix to all endpoints in this project."
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditCloseWrapper}>Cancel</Button>
          <Button onClick={handleEditConfirmWrapper} color="primary" variant="contained">Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProjectDialogs;
