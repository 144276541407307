import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, IconButton, Typography, Box } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import JSONInput from 'react-json-editor-ajrm';
import locale from "react-json-editor-ajrm/locale/en";

const ProjectDialogsDitails = ({
  deleteDialogOpen,
  dataDialogOpen,
  handleDeleteClose,
  handleDeleteConfirm,
  handleDataClose,
  handleDataUpdate,
  resourceToDelete,
  resourceToEdit,
  resourceData,
  setResourceData,
}) => {
  const [localResourceData, setLocalResourceData] = useState(resourceData);

  useEffect(() => {
    setLocalResourceData(resourceData);
  }, [resourceData]);

  const handleJsonChange = (data) => {
    if (!data.error) {
      setLocalResourceData(data.jsObject);
    }
  };

  // const handleUpdate = () => {
  //   console.log('Update', localResourceData);
  //   setResourceData(localResourceData);
  //   handleDataUpdate();
  // };

  return (
    <>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteClose}
      >
        <DialogTitle>
          Delete Resource
          <IconButton
            aria-label="close"
            onClick={handleDeleteClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete <strong>{resourceToDelete ? resourceToDelete.name : ''}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>Close</Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained">Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dataDialogOpen}
        onClose={handleDataClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          Resource Data - {resourceToEdit ? resourceToEdit.name : ''}
          <IconButton
            aria-label="close"
            onClick={handleDataClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Resource data
          </Typography>
          <Typography variant="body2" gutterBottom>
            Generated data for you {resourceToEdit ? resourceToEdit.name : ''} resource.
          </Typography>
          <Box sx={{ backgroundColor: '#2e2e2e', color: '#fff', padding: 2, borderRadius: 2 }}>
            <JSONInput
              id='json-editor'
              placeholder={localResourceData}
              locale={locale}
              viewOnly={true}
              height='400px'
              width='100%'
              onChange={handleJsonChange}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDataClose}>Close</Button>
          {/* <Button onClick={handleUpdate} color="primary" variant="contained">Update</Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProjectDialogsDitails;
