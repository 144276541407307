import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton, Box, Typography, Select, InputLabel, MenuItem, FormControl } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';
import fakerOptions from '../fekerOptions';

const EditResourceForm = ({ resource, open, onClose, onSave }) => {
  const [name, setName] = useState(resource.name);
  const [resourceSchema, setResourceSchema] = useState(resource.resource_schema);

  useEffect(() => {
    setName(resource.name);
    setResourceSchema(resource.resource_schema.map(field => {
      const [type, fakerType] = field.type.startsWith('faker.js.') ? ['faker.js', field.type.replace('faker.js.', '')] : [field.type, ''];
      return { ...field, type, fakerType };
    }));
  }, [resource]);

  const handleSave = async () => {
    try {
      const updatedSchema = resourceSchema.map(field => ({
        ...field,
        type: field.type === 'faker.js' ? `faker.js.${field.fakerType}` : field.type
      }));

      await axios.put(`/api/v1/resources/${resource.id}`, {
        name,
        resource_schema: updatedSchema
      });
      onSave();
      onClose();
    } catch (error) {
      console.error('Error updating resource', error);
    }
  };

  const handleResourceSchemaChange = (index, field, value) => {
    const updatedSchema = [...resourceSchema];
    updatedSchema[index][field] = value;
    setResourceSchema(updatedSchema);
  };

  const handleAddSchemaField = () => {
    setResourceSchema([...resourceSchema, { name: '', type: '', fakerType: '' }]);
  };

  const handleRemoveSchemaField = (index) => {
    const updatedSchema = resourceSchema.filter((_, i) => i !== index);
    setResourceSchema(updatedSchema);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        Edit Resource
        <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Resource Name"
          type="text"
          fullWidth
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Box mt={2}>
          <Typography variant="h6">Resource Schema</Typography>
          {resourceSchema.map((field, index) => (
            <Box key={index} display="flex" alignItems="center" mt={1}>
              <TextField
                label="Field Name"
                type="text"
                variant="outlined"
                value={field.name}
                onChange={(e) => handleResourceSchemaChange(index, 'name', e.target.value)}
                sx={{ mr: 1 }}
                fullWidth
              />
              <FormControl fullWidth sx={{ mr: 1 }}>
                <InputLabel id={`type-select-label-${index}`}>Type</InputLabel>
                <Select
                  labelId={`type-select-label-${index}`}
                  id={`type-select-${index}`}
                  value={field.type}
                  label="Type"
                  onChange={(e) => handleResourceSchemaChange(index, 'type', e.target.value)}
                >
                  <MenuItem value={"faker.js"}>Faker.js</MenuItem>
                  <MenuItem value={"string"}>String</MenuItem>
                  <MenuItem value={"number"}>Number</MenuItem>
                  <MenuItem value={"boolean"}>Boolean</MenuItem>
                  <MenuItem value={"array"}>Array</MenuItem>
                  <MenuItem value={"object"}>Object</MenuItem>
                  <MenuItem value={"date"}>Date</MenuItem>
                  <MenuItem value={"null"}>Null</MenuItem>
                </Select>
              </FormControl>
              {field.type === 'faker.js' && (
                <FormControl fullWidth sx={{ mr: 1 }}>
                  <InputLabel id={`faker-type-select-label-${index}`}>Faker Type</InputLabel>
                  <Select
                    labelId={`faker-type-select-label-${index}`}
                    id={`faker-type-select-${index}`}
                    value={field.fakerType}
                    label="Faker Type"
                    onChange={(e) => handleResourceSchemaChange(index, 'fakerType', e.target.value)}
                  >
                    {fakerOptions.map((option) => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <IconButton aria-label="remove" onClick={() => handleRemoveSchemaField(index)}>
                <CloseIcon />
              </IconButton>
            </Box>
          ))}
          <Button onClick={handleAddSchemaField} sx={{ mt: 1 }}>Add Field</Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary" variant="contained">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditResourceForm;
