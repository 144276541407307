import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Typography, Link, Container, Alert } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';

const FormContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '5%',
  alignItems: 'center',
  minHeight: '100vh',
});

const FormBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '30px',
  backgroundColor: '#fff',
  borderRadius: '10px',
  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  width: '100%',
  maxWidth: '400px',
});

const StyledButton = styled(Button)({
  borderRadius: '20px',
  marginTop: '20px',
  backgroundColor: '#007BFF',
  '&:hover': {
    backgroundColor: '#0056b3',
  },
});

const validationSchema = yup.object({
  name: yup
    .string('Enter your name')
    .required('Name is required'),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(6, 'Password should be of minimum 6 characters length')
    .required('Password is required'),
  confirmPassword: yup
    .string('Confirm your password')
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

const SignUpForm = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_GOOGLE_AUTH_URL}`;
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.post('/api/v1/register', {
          name: values.name,
          email: values.email,
          password: values.password,
        });

        if (response.status === 201) {
          setSuccess(true);
          navigate('/login');
        }
      } catch (error) {
        setError(error.response?.data?.message || 'An error occurred');
      }
    },
  });

  return (
    <FormContainer maxWidth="xs">
      <FormBox>
        <Typography component="h1" variant="h5" sx={{ paddingBottom: 5 }}>
          Sign up
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">Registration successful! Redirecting...</Alert>}
        <Button
          fullWidth
          variant="contained"
          startIcon={<i className="fab fa-google"></i>}
          sx={{ mb: 2, backgroundColor: '#4285F4', color: '#fff' }}
          onClick={handleGoogleLogin}
        >
          Sign in with Google
        </Button>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            autoFocus
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            autoComplete="confirm-password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
          />
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            size="large"
          >
            Sign up
          </StyledButton>
        </form>
        <Box mt={2} display="flex" justifyContent="space-between" width="100%">
          <Typography variant="body2">
            Already have an account?{' '}
            <Link href="/login" variant="body2">
              Login here
            </Link>
          </Typography>
        </Box>
      </FormBox>
    </FormContainer>
  );
};

export default SignUpForm;
