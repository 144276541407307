import React from 'react';
import { ListItemButton, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton, Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ProjectItem = ({ project, onClick, onMenuOpen }) => {
  return (
    <ListItemButton onClick={onClick} sx={{ mb: 2, borderRadius: '10px', boxShadow: 1 }}>
      <ListItemIcon>
        <Box sx={{
          width: 40,
          height: 40,
          borderRadius: '50%',
          backgroundColor: '#673ab7',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff',
          fontWeight: 'bold'
        }}>
          {project.name.charAt(0).toUpperCase()}
        </Box>
      </ListItemIcon>
      <ListItemText primary={project.name} />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="more options" onClick={(event) => onMenuOpen(event, project)}>
          <MoreVertIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItemButton>
  );
};

export default ProjectItem;
