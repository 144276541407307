import React from 'react';

const Docs = () => (
  <div>
    <h1>Documentation</h1>
    <p>Here will be the documentation...</p>
  </div>
);

export default Docs;
