import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, IconButton, List, LinearProgress } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import ProjectItem from './ProjectItem';
import ProjectDialogs from './ProjectDialogs';

const ProjectsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  min-height: 100vh;
`;

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 800px;
  padding: 20px 0;
`;

const ProjectList = styled(List)`
  width: 100%;
  max-width: 800px;
  border-radius: 10px;
  padding: 20px;
`;

const Projects = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [projectCount, setProjectCount] = useState(0);
  const [maxProjects, setMaxProjects] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuSelectedProject, setMenuSelectedProject] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [dialogSelectedProject, setDialogSelectedProject] = useState(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');
  const [newProjectPrefix, setNewProjectPrefix] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get('/api/v1/projects');
        setProjects(response.data || []); // Ensure projects is always an array
      } catch (error) {
        if (error.response.status === 401) {
          navigate('/login');
        }
        console.error('Error fetching projects', error);
      }
    };

    const fetchProjectCount = async () => {
      try {
        const response = await axios.get('/api/v1/projects/count');
        setProjectCount(response.data.count);
        setMaxProjects(response.data.max);
      } catch (error) {
        console.error('Error fetching project count', error);
      }
    };

    fetchProjects();
    fetchProjectCount();
  }, [navigate]);

  const handleProjectClick = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  const handleMenuOpen = (event, project) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setMenuSelectedProject(project);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuSelectedProject(null);
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    setDialogSelectedProject(menuSelectedProject);
    setDeleteDialogOpen(true);
    handleMenuClose();
  };

  const handleDeleteClose = () => {
    setDeleteDialogOpen(false);
    setDialogSelectedProject(null);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`/api/v1/projects/${dialogSelectedProject.id}`);
      setProjects(projects.filter(project => project.id !== dialogSelectedProject.id));
      setProjectCount(prev => prev - 1); // Decrease project count
    } catch (err) {
      console.error('Error deleting project', err);
      setError(err.response?.data?.error || 'An unexpected error occurred');
    }
    handleDeleteClose();
  };

  const handleCreateClick = () => {
    setCreateDialogOpen(true);
  };

  const handleCreateClose = () => {
    setCreateDialogOpen(false);
    setNewProjectName('');
    setNewProjectPrefix('');
    setError('');
  };

  const handleCreateConfirm = async () => {
    try {
      const response = await axios.post('/api/v1/projects', { name: newProjectName, prefix: newProjectPrefix });
      setProjects([...projects, response.data]);
      setProjectCount(prev => prev + 1); // Increase project count
      handleCreateClose();
    } catch (err) {
      setCreateDialogOpen(true);
      console.error('Error creating project', err);
      setError(err.response?.data?.error || 'An unexpected error occurred');
    }
  };

  const handleEditClick = (event) => {
    event.stopPropagation();
    setDialogSelectedProject(menuSelectedProject);
    setNewProjectName(menuSelectedProject.name);
    setNewProjectPrefix(menuSelectedProject.prefix);
    setEditDialogOpen(true);
    handleMenuClose();
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setDialogSelectedProject(null);
    setNewProjectName('');
    setNewProjectPrefix('');
    setError('');
  };

  const handleEditConfirm = async () => {
    try {
      const response = await axios.put(`/api/v1/projects/${dialogSelectedProject.id}`, { name: newProjectName, prefix: newProjectPrefix });
      setProjects(projects.map(project => project.id === dialogSelectedProject.id ? response.data : project));
      handleEditClose();
    } catch (error) {
      console.error('Error updating project', error);
      setError(error.response?.data?.error || 'An unexpected error occurred');
    }
  };

  return (
    <ProjectsContainer>
      <Header>
        <Typography variant="h5">Projects</Typography>
        <Box display="flex" alignItems="center">
          <LinearProgress
            variant="determinate"
            value={(projectCount / maxProjects) * 100}
            sx={{ width: '200px', marginRight: '10px' }}
          />
          <Typography variant="body2">{projectCount} / {maxProjects}</Typography>
          <IconButton color="primary" aria-label="add project" onClick={handleCreateClick}>
            <AddCircleIcon />
          </IconButton>
        </Box>
      </Header>
      <ProjectList>
        {projects.length === 0 ? (
          <Typography>No projects yet...</Typography>
        ) : (
          projects.map((project) => (
            <ProjectItem
              key={project.id}
              project={project}
              onClick={() => handleProjectClick(project.id)}
              onMenuOpen={handleMenuOpen}
            />
          ))
        )}
      </ProjectList>

      <ProjectDialogs
        anchorEl={anchorEl}
        menuSelectedProject={menuSelectedProject}
        deleteDialogOpen={deleteDialogOpen}
        createDialogOpen={createDialogOpen}
        editDialogOpen={editDialogOpen}
        newProjectName={newProjectName}
        newProjectPrefix={newProjectPrefix}
        setNewProjectName={setNewProjectName}
        setNewProjectPrefix={setNewProjectPrefix}
        handleMenuClose={handleMenuClose}
        handleDeleteClick={handleDeleteClick}
        handleDeleteClose={handleDeleteClose}
        handleDeleteConfirm={handleDeleteConfirm}
        handleCreateClose={handleCreateClose}
        handleCreateConfirm={handleCreateConfirm}
        handleEditClick={handleEditClick}
        handleEditClose={handleEditClose}
        handleEditConfirm={handleEditConfirm}
        error={error}
        setError={setError}
      />
    </ProjectsContainer>
  );
};

export default Projects;
