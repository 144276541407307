import React, { useState, useContext, useEffect } from 'react';
import { TextField, Button, Box, Typography, Link, Container, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { AuthContext } from '../context/AuthContext';

const FormContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '5%',
  minHeight: '100vh',
});

const FormBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '30px',
  backgroundColor: '#fff',
  borderRadius: '10px',
  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  width: '100%',
  maxWidth: '400px',
});

const StyledButton = styled(Button)({
  borderRadius: '20px',
  marginTop: '20px',
  backgroundColor: '#007BFF',
  '&:hover': {
    backgroundColor: '#0056b3',
  },
});

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { user, login } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      navigate('/projects');
    }
  }, [user, navigate]);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      await login(email, password);
      navigate('/projects');
    } catch (err) {
      setError('Invalid email or password');
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_GOOGLE_AUTH_URL}`;
  };

  return (
    <FormContainer maxWidth="xs">
      <FormBox>
        <Typography component="h1" variant="h5" sx={{ paddingBottom: 5 }}>
          Sign in
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        <Button
          fullWidth
          variant="contained"
          startIcon={<i className="fab fa-google"></i>}
          sx={{ mb: 2, backgroundColor: '#4285F4', color: '#fff' }}
          onClick={handleGoogleLogin}
        >
          Sign in with Google
        </Button>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <StyledButton
          type="submit"
          fullWidth
          variant="contained"
          size="large"
          onClick={handleLogin}
        >
          Login
        </StyledButton>
        <Box mt={2} display="flex" justifyContent="space-between" width="100%">
          <Link href="#" variant="body2">
            Forgot password?
          </Link>
          <Link href="/signup" variant="body2">
            Sign up
          </Link>
        </Box>
      </FormBox>
    </FormContainer>
  );
};

export default LoginForm;
