import React, { useState } from 'react';
import axios from 'axios';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton, Box, Typography, Select, InputLabel, MenuItem, FormControl, Alert } from '@mui/material';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import fakerOptions from './fekerOptions';

const NewResourceButton = ({ projectId, onResourceCreated }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [resourceName, setResourceName] = useState('');
  const [resourceSchema, setResourceSchema] = useState([{ name: '', type: '', fakerType: '' }]);
  const [error, setError] = useState('');

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setResourceName('');
    setResourceSchema([{ name: '', type: '', fakerType: '' }]);
    setError('');
  };

  const handleResourceNameChange = (event) => {
    setResourceName(event.target.value);
  };

  const handleResourceSchemaChange = (index, field, value) => {
    const updatedSchema = [...resourceSchema];
    updatedSchema[index][field] = value;
    setResourceSchema(updatedSchema);
  };

  const handleAddSchemaField = () => {
    setResourceSchema([...resourceSchema, { name: '', type: '', fakerType: '' }]);
  };

  const handleRemoveSchemaField = (index) => {
    const updatedSchema = resourceSchema.filter((_, i) => i !== index);
    setResourceSchema(updatedSchema);
  };

  const handleCreateResource = async () => {
    try {
      const response = await axios.post('/api/v1/resources', {
        name: resourceName,
        project_id: projectId,
        resource_schema: resourceSchema.map(field => {
          const { fakerType, ...rest } = field;
          if (field.type === 'faker.js' && fakerType) {
            rest.fakerType = fakerType;
          }
          return rest;
        })
      });
      onResourceCreated(response.data);
      handleDialogClose();
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred');
      console.error('Error creating resource', error);
    }
  };

  return (
    <Box sx={{ paddingBottom: '3%' }}>
      <Button variant="contained" color="primary" sx={{ borderRadius: '20px' }} startIcon={<AddIcon />} onClick={handleDialogOpen}>
        New resource
      </Button>

      <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>
          New Resource
          <IconButton aria-label="close" onClick={handleDialogClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              {error}
            </Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Resource Name"
            type="text"
            fullWidth
            required
            variant="outlined"
            value={resourceName}
            onChange={handleResourceNameChange}
          />
          <Box mt={2}>
            <Typography variant="h6">Resource Schema</Typography>
            {resourceSchema.map((field, index) => (
              <Box key={index} display="flex" alignItems="center" mt={1}>
                <TextField
                  label="Field Name"
                  type="text"
                  variant="outlined"
                  value={field.name}
                  required
                  onChange={(e) => handleResourceSchemaChange(index, 'name', e.target.value)}
                  sx={{ mr: 1 }}
                  fullWidth
                />
                <FormControl fullWidth sx={{ mr: 1 }}>
                  <InputLabel id={`type-select-label-${index}`}>Type</InputLabel>
                  <Select
                    labelId={`type-select-label-${index}`}
                    id={`type-select-${index}`}
                    value={field.type}
                    label="Type"
                    onChange={(e) => handleResourceSchemaChange(index, 'type', e.target.value)}
                  >
                    <MenuItem value={"faker.js"}>Faker.js</MenuItem>
                    <MenuItem value={"string"}>String</MenuItem>
                    <MenuItem value={"number"}>Number</MenuItem>
                    <MenuItem value={"boolean"}>Boolean</MenuItem>
                    <MenuItem value={"array"}>Array</MenuItem>
                    <MenuItem value={"object"}>Object</MenuItem>
                    <MenuItem value={"date"}>Date</MenuItem>
                    <MenuItem value={"null"}>Null</MenuItem>
                  </Select>
                </FormControl>
                {field.type === 'faker.js' && (
                  <FormControl fullWidth sx={{ mr: 1 }}>
                    <InputLabel id={`faker-type-select-label-${index}`}>Faker Type</InputLabel>
                    <Select
                      labelId={`faker-type-select-label-${index}`}
                      id={`faker-type-select-${index}`}
                      value={field.fakerType}
                      label="Faker Type"
                      onChange={(e) => handleResourceSchemaChange(index, 'fakerType', e.target.value)}
                    >
                      {fakerOptions.map((option) => (
                        <MenuItem key={option} value={option}>{option}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <IconButton aria-label="remove" onClick={() => handleRemoveSchemaField(index)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            ))}
            <Button onClick={handleAddSchemaField} sx={{ mt: 1 }}>Add Field</Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleCreateResource} color="primary" variant="contained">Create</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default NewResourceButton;
