import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px 20px;
`;

const Title = styled.h1`
  font-size: 32px;
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  font-size: 18px;
  margin-bottom: 40px;
`;

const Main = () => {
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    // get cookie token
    const token = localStorage.getItem('token');
    // if token exists, redirect to projects page
    if (token) {
      navigate('/projects');
    } else {
      // if token does not exist, redirect to login page
      navigate('/login');
    }
  };

  return (
    <MainContainer>
      <script src="https://js.stripe.com/v3/"></script>
      <Title>The easiest way to mock REST APIs</Title>
      <Subtitle>Quickly setup endpoints, generate custom data, and perform operations on it using RESTful interface</Subtitle>
      <Button variant="contained" size="large" sx={{ borderRadius: 28 }} onClick={handleGetStartedClick}>
        Get started
      </Button>
    </MainContainer>
  );
};

export default Main;
