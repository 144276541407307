import React, { useState } from 'react';
import { Box, Typography, Button, IconButton, Slider } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import styled from 'styled-components';
import EditResourceForm from './EditResourceForm';

const ResourceBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ResourceItem = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  position: relative;
  &:hover .resource-actions {
    display: flex;
  }
`;

const marks = [
  { value: 20, label: '20' },
  { value: 40, label: '40' },
  { value: 60, label: '60' },
  { value: 80, label: '80' },
  { value: 100, label: '100' },
];

const ResourceActions = styled(Box)`
  display: none;
  align-items: center;
`;

const ResourceList = ({ resources, project, handleSliderChange, handleDataClick, handleDeleteClick, handleResourceUpdated }) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [resourceToEdit, setResourceToEdit] = useState(null);

  const handleEditClick = (resource) => {
    setResourceToEdit(resource);
    setEditDialogOpen(true);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setResourceToEdit(null);
  };

  const handleResourceSave = () => {
    handleResourceUpdated();
    handleEditClose();
  };

  return (
    <ResourceBox>
      {resources.length === 0 ? (
        <Typography>No resources yet...</Typography>
      ) : (
        resources.map((resource) => (
          <ResourceItem key={resource.id}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '70%' }}>
              <Typography variant="h6" sx={{ textAlign: 'center', flexGrow: 1 }}>
                <a href={`http://${project.id}.${process.env.REACT_APP_DOMAIN}${project.prefix}${resource.name}`}>{resource.name}</a>
              </Typography>
              <Typography variant="h6" sx={{ textAlign: 'center', flexGrow: 1 }}>Data count: {resource.count}</Typography>
              <Slider
                value={resource.count}
                min={0}
                max={100}
                step={1}
                marks={marks}
                valueLabelDisplay="auto"
                onChange={(event, value) => handleSliderChange(resource, value)}
                sx={{ width: '50%', flexGrow: 1 }}
              />
            </Box>
            <ResourceActions className="resource-actions">
              <Button size="small" variant="contained" sx={{ marginRight: 1 }} onClick={() => handleDataClick(resource)}>Data</Button>
              <IconButton size="small" sx={{ marginRight: 1 }} onClick={() => handleEditClick(resource)}>
                <EditIcon />
              </IconButton>
              <IconButton size="small" color="error" onClick={() => handleDeleteClick(resource)}>
                <DeleteIcon />
              </IconButton>
            </ResourceActions>
          </ResourceItem>
        ))
      )}
      {resourceToEdit && (
        <EditResourceForm
          resource={resourceToEdit}
          open={editDialogOpen}
          onClose={handleEditClose}
          onSave={handleResourceSave}
        />
      )}
    </ResourceBox>
  );
};

export default ResourceList;
