import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Docs from './pages/Docs';
import LoginForm from './components/LoginForm';
import SignUpForm from './components/SignUpForm';
import { AuthProvider } from './context/AuthContext';
import ProjectsContainer from './components/project/ProjectsContainer';
import ProjectDetails from './components/project/ProjectDetails';
import styled from 'styled-components';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContainer>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            
            <Route path="/docs" element={<Docs />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/signup" element={<SignUpForm />} />
            <Route path="/projects" element={<ProjectsContainer />} />
            <Route path="/projects/:projectId" element={<ProjectDetails />} />
          </Routes>
        </AppContainer>
      </AuthProvider>
    </Router>
  );
}

export default App;
